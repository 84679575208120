import React from 'react'
import FeatureWork from './FeatureIntro.jpg';
import './FeatureWork.scss';

function FeatureWorkSection() {
    return (
        <div className="FeatureWork-Container">
            <h1>VR / AR Design & DEVELOPMENT</h1>
            <div className="featurework-Intro">
                <img 
                    src={FeatureWork}
                    alt="FeatureWork"
                />
            </div>
            
            <p>VR and AR are my main focus when I studied in Australia with a master's degree.</p>
            <p>I have made three projects which are related to therapy, education and entertainment.</p>
        </div>
    )
}

export default FeatureWorkSection
