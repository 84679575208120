import React from "react";
import "./HeroPage.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { scroller } from "react-scroll";

const scrollToElement = (element) => {
  scroller.scrollTo(element, {
    duration: 1000,
    delay: 100,
    smooth: true,
    offset: -100,
  });
};

const HeroPage = () => {
  let i = 0,
    text;
  text = "Ting Chieh Lin";

  return (
    // style={{marginTop:'30px'} className="dib br3 pa3 ma2 bw2 shadow"}
    <div className="hero">
      {/* <img src="https://robohash.org/testte" alt="Ting"/> */}
      {/* source folder is not working for webpack , we have to import image or put it to public folider*/}
      {/* <img className="heroPage" src={meImage} alt="Ting"/> */}
      <div className="herointro">
        <h1>
          Hi there ! My name is Ting Chieh Lin who loves to discover "Why" and
          "How" behind novel things.
        </h1>
        <p>The cleverness comes from hard work，not a gift</p>
        <div onClick={() => scrollToElement("Web")}>
          <ExpandMoreIcon style={{ color: "white", fontSize: "32" }} />
        </div>
      </div>
    </div>
  );
};

export default HeroPage;
