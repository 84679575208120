export const featureworks = [
    {
        id:0,
        time:'01 November 2019',
        type:'AR | UI & UX | Unity3D',
        name:'Royal Botanic Gardens Victoria App',
        description:"No matter who you are plant lovers or people who go out with their family in the Royal Botanic Gardens. You are able to use the app to learn and enjoy any events in Melbourne.",
        img:'RoyalBotanicGarden',
        link:'/royalbotanicgarden'
    },
    {
        id:1,
        time:'15 July 2020',
        type:'VR | Game Design & Development | UX | Unity3D',
        name:'Animal Village',
        description:"To resolve pressure during the pandemic, users play the game which is playful and hilarious experience in immersive environment.",
        img:'AnimalVillage',
        link:'/animalvillage'
    }
]
