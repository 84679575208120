import React,{Component} from 'react';
import './ARPet.css';
import ARPetPng from './ARPetportfolio.jpg';
import ReactPlayer from 'react-player'

// https://youtu.be/l--s2xLZx8c
class ARPet extends Component {
    
    render() {
        
        return (
            <div >
                <div className="ARPetIntro">
                    <img alt={"ARPetDescription"}
                        src={ARPetPng}
                        key="0"
                    />
                      {/* <YouTube
                        videoId="l--s2xLZx8c"
                        opts={opts}
                        onReady={this._onReady}
                    /> */}
                <ReactPlayer
                    className="videoplayer"
                    url={`https://youtu.be/l--s2xLZx8c`}
                    config={{
                        youtube: {
                        playerVars: { showinfo: 1 } 
                        }
                    }}
                />
                </div>
            
            </div>
        );
    }
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

}

export default ARPet;


