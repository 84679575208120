import React, { Component } from "react";
import "./AnimalVillage.scss";
// import ReactPlayer from 'react-player'
//import VRpic from './me.JPG';

export default class xr extends Component {
  render() {
    return (
      <div style={{height: '100%'}}>
        <div className="intro-container">
          <div className="overlay"></div>
          <div className="Info">
            <div className="Info_text">
              <div className="Info_title">ANIMAL VILLAGE</div>
              <div className="Info_intro">
                <h2>Objectives</h2>
                <div className="Info_intro-text marginBottom">
                  <p>
                    The goal of my projects are the main focus on immersive
                    experience with advanced technology in VR to support players
                    to release relieve stress, do exercise anywhere and make
                    players interested in the playful game by a variety of
                    challenge design which contains to save animals, defeat
                    enemies coming. A group of audiences is focus on ages
                    between ages 20s to 35.
                  </p>
                </div>
                <div className="Info_intro-text">
                  <h2>Concept</h2>
                  <p>
                    The Direction is to encourage people to play the game; thus,
                    when they do exercise with high motivation and engagements
                    environment to active them. I design VR experience more
                    playful by adding animals and interactive elements to play
                    with them.
                  </p>
                </div>
              </div>
            </div>
            <div className="Linkbutton">
              <div className="button_link purple_button">
                <a rel="noopener noreferrer" href="./animalvillageintro">
                  <span>Product Design Process</span>
                </a>
              </div>
              <div className="button_link">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.slideshare.net/secret/FGXtUjAoffCwwu"
                >
                  <span>Design Document</span>
                </a>
              </div>
              <div className="button_link">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.slideshare.net/secret/Fp5njDj9m4QUZu "
                >
                  <span>Presentation Board</span>
                </a>
              </div>
              <div className="button_yellow">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://youtu.be/XHW_47Ubs2s"
                >
                  <span>Watch Launch Trailer</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//https://youtu.be/XHW_47Ubs2s
//https://www.slideshare.net/secret/Fp5njDj9m4QUZu   Presentation Board
//https://www.slideshare.net/secret/FGXtUjAoffCwwu   Design Document
/*


Project Overview:

The novel coronavirus outbreak has drawn increased international attention 
as the virus quickly spreads across the world. This forced people to stay home. 
From my perspective and interest of playing video games, 
I decided to make an interactive game through highly immersive experience in virtual
 reality. As a result, no matter when people stay home or are tired after work, they
  feel comfortable and enjoyable during the game.

ANIMAL VILLAGE is a playful and encouraged virtual reality game. During the game,
you play as a brave farmer and are assigned to save the village because enemies invade their home.
There are 5 waves that  have a variety of design elements in order to make you explore Animal village.
Immerse yourself in deep environmental interactions,
storying telling, playful exploration, and fighting combat.
*/

{
  /* <div className="xroverall">
                    <div className="xrcontainer">
                        <div className="xrcontent">
                            I am still developing and will finish my graduation work by July in 2020.
                        </div>
                        <div className="xrpic">
                            <img alt={"VRpic"}
                                src={VRpic}
                                key="0"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
</div> */
}
