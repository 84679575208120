import React from 'react'
import UiuxWorkContainer from '../uiuxWorkContainer/uiuxWorkContainer';
import './UiUxWorkList.css';

const UiUxWorkList = props => {
    const {uiuxworksfoo} = props;
    const uiuxworkComponent = uiuxworksfoo.map((project,i) => {
        return (
            <UiuxWorkContainer
                key={i}
                id={uiuxworksfoo[i].id}
                name={uiuxworksfoo[i].name}
                time={uiuxworksfoo[i].time}
                type={uiuxworksfoo[i].type}
                description={uiuxworksfoo[i].description}
                img={uiuxworksfoo[i].img}
                link={uiuxworksfoo[i].link}
            />
        );
    })
    return (
        <div className="uiuxworkContainer">
            {uiuxworkComponent}
        </div>
    )
};

export default UiUxWorkList;