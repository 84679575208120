import React from 'react';
import './IHelp.css';
import IHelpPng from './ihelp.png';

const IHelp = () => {
    return (
        <div className="IHelpIntro"> 
            <img alt={"IHelp"}
                src={IHelpPng}
                key="1"
            />
        </div>
    );
};

export default IHelp;

