import React from 'react';
import './FoodFoundry.css';
import FoodFoundryPng from './foodfoundry.png';

const FoodFoundry = () => {
    return (
        <div >
            <div className="FoodFoundryIntro"> 
                <img alt={"FoodFoundry"}
                    src={FoodFoundryPng}
                    key="2"
                />
            </div>
        </div>
    );
};

export default FoodFoundry;

