export const uiuxworks = [
    {
        id:9,
        name:'Animal Village',
        time:'15 July 2020',
        type:"User Research | Design Thinking | VR Design | Development",
        description:"Alleviate pressure during the pandemic using fun ways to encourage people to grab animals",
        img:"AnimalVillageImage",
        link:"/animalvillage"
    },
    {
        id:8,
        name:'Royal Botanic Gardens Victoria App',
        time:'01 November 2019',
        type:"User Research | Design Thinking | App Design | AR Development",
        description:"No matter who you are plant lovers or people who go out with their family in the Royal Botanic Gardens. You are able to use the app to learn and enjoy any events in Melbourne",
        img:"RoyalBotanicGarden",
        link:"/royalbotanicgarden"
    },
    // {
    //     id:7,
    //     name:'Melbourne Music Festival',
    //     time:'05 April 2019',
    //     type:"App Design",
    //     description:"The App is for people to look for music events in Melbourne. It provides high quality in order to let people enjoy music festival",
    //     img:"MusicFestival",
    //     link:"/musicfestival"
    // },
    // {
    //     id:6,
    //     name:'Coffee research & Design System',
    //     time:'10 June 2019',
    //     type:"User Research | Design Thinking | App Design",
    //     description:"The research of coffee shops using loyalty cards and the ux research to develop an app for replacement possibly",
    //     img:"DesignCoffee",
    //     link:"/coffeeresearch"
    // },
    // {
    //     id:5,
    //     name:'Food waste reduction',
    //     time:'07 April 2019',
    //     type:"Design Thinking | App Design",
    //     description:"Design an app for people to improve health cooking at home and make friends in order to the reduction of food food waste",
    //     img:"DesignFoodWaste",
    //     link:"/foodwaste"
    // },
    // {
    //     id:4,
    //     name:'Starbucks Solutions',
    //     time:'10 June 2019',
    //     type:"Design Thinking | App Design",
    //     description:"Research for Starbucks's problems and design an App to improve user experience",
    //     img:"DesignStarbucks",
    //     link:"/startbucks"
    // },
    // {
    //     id:3,
    //     name:'Game Meet App',
    //     time:'15 September 2020',
    //     type:"Web Design | Development",
    //     description:"The new generation of gmaing platform for gamers to make friends and look for teammates",
    //     img:"GameMeet",
    //     link:"/gamemeet"
    // },
    // {
    //     id:2,
    //     name:'AR Pet',
    //     time:'22 December 2017',
    //     type:"App Design",
    //     description:"Using an App to keep a pet for you. Users are able to interact with their pets and play games",
    //     img:"ARpet",
    //     link:"/arpet"
    // },
    // {
    //     id:1,
    //     name:'I Help',
    //     time:'15 November 2017',
    //     type:"App Design",
    //     description:"If everyone use an app to ask for help, this will be good to solve their issues",
    //     img:"IHelp",
    //     link:"/ihelp"
    // },
    // {
    //     id:0,
    //     name:'Food Foundry',
    //     time:'11 October 2017',
    //     type:"App Design",
    //     description:"Learn to cook healthy and have a complete plan for you. Users do not have to worry what they should cook for today's meal",
    //     img:"FoodFoundry",
    //     link:"/foodfoundry"
    // },
]
