import React, { Component } from "react";
import "./Footer.scss";

import SvgLinkedin from "./SvgLinkedin";
// import SvgInstagram from './SvgInstagram';
// import SvgFacebook from './SvgFacebook';
// import SVGBehance from './SVGBehance';
import SVGTwitter from "./SVGTwitter";
import SVGBehance from '../Footer/SVGBehance';
import SVGYoutuber from "./SVGYoutuber";

import { IconContext } from "react-icons";
import { FaGithub, FaMedium } from "react-icons/fa";

class Footer extends Component {
  state = {
    value: 0,
  };

  // handleChange = (event,value) => {

  // };
  render() {
    return (
      <div>
        <div className="Footer">
          <div className="Copyright">
            © Copyright 2024. Developed by Ting Chieh Lin
          </div>
          <div className="FooterSocial">
            <a
              className="socailIcon"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/cooloojayoo/"
            >
              <SvgLinkedin />
            </a>
            <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
              <a
                className="socailIcon"
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/TingChiehLin"
              >
                <FaGithub />
              </a>
            </IconContext.Provider>
            <a className="socailIcon" 
               target="_blank" rel="noopener noreferrer" href="https://www.behance.net/jaylinxr"><SVGBehance/></a>
            <a
              className="socailIcon"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UC1rMgKD4Rn-7aVcymjlvhfQ/"
            >
              <SVGYoutuber />
            </a>
            {/*<IconContext.Provider value={{ color: "white",size: "1.5em"}}>*/}
            {/*  <a className="socailIcon" target="_blank" rel="noopener noreferrer" href="https://medium.com/@JayLinXR"><FaMedium/></a>*/}
            {/*</IconContext.Provider>*/}
            {/* <a
              className="socailIcon"
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/cooloojayoo"
            >
              <SVGTwitter />
            </a> */}
            {/* <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/lintingchieh_jason/"><SvgInstagram/></a> */}
            {/* <a className="socailIcon" target="_blank" rel="noopener noreferrer" href="https://www.behance.net/dflujack486c"><SVGBehance/></a> */}
            {/* <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/tingchang.lin.1"><SvgFacebook/></a> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
