import React from 'react';
import './TopWork.scss';
import FeatureWorkContainer from '../FeatureWorkContainer/FeatureWorkContainer';
import DevelopmentWorkContainer from '../DevelopmentWorkContainer/DevelopmentWorkContainer';
import {featureworks} from './featurework';

const TopWork = () => {
  const featureworkComponent = featureworks.map((project,i) => {
    return (
        <FeatureWorkContainer
          key={i} 
          id={featureworks[i].id}
          name={featureworks[i].name}
          time={featureworks[i].time}
          type={featureworks[i].type}
          description={featureworks[i].description}
          img={featureworks[i].img}
          link={featureworks[i].link}
        />
    );
  })

  const developmentworkContainer = () => {
    return <p>test</p>
  }
    
  return (
   <div className="featureworkcontainer">
     {featureworkComponent}
     {/* {developmentworkContainer} */}
   </div>
  );
};

export default TopWork;