import React from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import WorkIcon from '@material-ui/icons/Work';
// import SatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
// import MailIcon from '@material-ui/icons/Mail';
import FaceIcon from '@material-ui/icons/Face';
import SchoolIcon from '@material-ui/icons/School';
import WebIcon from '@material-ui/icons/Web';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
// import {Link} from 'react-router-dom';
import {scroller} from 'react-scroll';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';

const scrollToElement = (element) => {
    scroller.scrollTo(element,{
       duration:1000,
       delay:100,
       smooth:true,
       offset:-100
    });
 }

const IconArray = props => {
   
function IconResult (number) {
    switch (number) {
        case 0:
            return <FaceIcon />
       
        case 1:
            return <WebIcon/>
        
        case 2:
            return <VideogameAssetIcon />
        
        case 3:
            return <WorkIcon />
        case 4:
            return <SchoolIcon />
    }
}

    
    return (
        <ListItemIcon>
            {IconResult(props.index)}
        </ListItemIcon>
    );
};

export default IconArray;
