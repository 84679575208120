import React, { Component } from 'react'
// import { Document, Page } from 'react-pdf';
// import CoffineResearchPDF from './CoffineResearch.pdf';
import CoffeeResearchPNG from './CoffineResearch.jpg';
import './CoffeeResearch.css';

export default class CoffeeResearch extends Component {
    // state = {
    //     numPages: null,
    //     pageNumber: 1,
    // }

    // onDocumentLoadSuccess = ({numPages}) => {
    //     this.setState({numPages});
    // }

    render() {
        // const { pageNumber, numPages } = this.state;
        return (
            <div>
                 <div className="CoffeeResearchIntro">
                    <img alt={"ARPetDescription"}
                         src={CoffeeResearchPNG}
                         key="0"
                    />
                 </div>
                 {/* <Document
                    file= {CoffineResearchPDF}
                    renderInteractiveForms = {true}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                    <Page pageNumber={pageNumber} />
                </Document> */}
            </div>
        )
    }
}
