import React, { Component } from 'react'
import MMFPng from './MelbourneMusic.jpg';
import './MusicFestival.css';

export default class MusicFestival extends Component {
    render() {
        return (
            <div className="layout">
                <div className="MMFContainer">
                    {/* <h1 className="title">Melbourne Music Festival</h1> */}
                    <img
                        src={MMFPng}
                        alt="MelbourneMusicFestival"
                        key="1"
                    />
                    <h1 className="title">Prototype</h1>
                    <div className="Link">
                       
                        <div className="Wireframe">
                            <a target="_blank" rel="noopener noreferrer" href="https://8u4sia.axshare.com/#id=ygcexu&p=login"><h2>Axure Wireframe Demo</h2></a>
                            <p></p>
                        </div>
                        <div className="Prototype">
                            <a target="_blank" rel="noopener noreferrer" href="https://3nibu5.axshare.com/#id=i82y05&p=log_in"><h2>Axure Prototype Demo</h2></a>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
