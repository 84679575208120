import React from "react";

function SVGYoutuber() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1.8em" height="1.5em"
      viewBox="0 0 24 17"
    >
      <defs>
        <path
          id="path-1"
          d="M0.0118032787 0.18904918L23.8986885 0.18904918 23.8986885 16.9180328 0.0118032787 16.9180328z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1127 -303) translate(1127 303)">
          <g>
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <path
              fill="#D9252A"
              d="M23.4 2.801A2.993 2.993 0 0021.285.688C19.423.19 11.95.19 11.95.19s-7.473 0-9.337.5A2.993 2.993 0 00.5 2.8C0 4.665 0 8.553 0 8.553s0 3.889.5 5.753a2.993 2.993 0 002.112 2.113c1.864.499 9.337.499 9.337.499s7.474 0 9.337-.5a2.993 2.993 0 002.113-2.112c.5-1.864.5-5.753.5-5.753s0-3.888-.5-5.752"
              mask="url(#mask-2)"
            ></path>
          </g>
          <path
            fill="#FFFFFE"
            d="M9.55947541 12.1382951L15.7681967 8.55363934 9.55947541 4.96859016 9.55947541 12.1382951"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SVGYoutuber;
