import React from 'react';
import {Link} from 'react-router-dom';
import './FeatureWorkContainer.scss';

import AnimalVillage from './img/AnimalVillage.jpg';
import RoyalBotanicGarden from '../uiuxWorkContainer/pics/RoyalBotanicGarden.jpg';

const images = {
    AnimalVillage,
    RoyalBotanicGarden
}

export default function FeatureWorkContainer(props) {
    const  {name,time,type,description,img,link} = props;
    return (
        <>  
            <div className="featurework-Grid">
                <Link to={link}>                              
                    {/* <a href={link}> */}
                    <div className="featurework-layout shadow-3 grow bw2" 
                        style={{
                        backgroundImage: `url(${images[img]})`,
                        backgroundRepeat:`no-repeat`,
                        backgroundSize:'cover',
                        backgroundPosition:'center center',
                        borderRadius:'3% 3%',
                        }}>
                    </div>
                </Link> 
                <div className="feature-into">
                    <div className="feature-into-name">{name}</div>
                    <div className="feature-into-container">
                        <div className="feature-into-time">{time} </div>
                        <div className="feature-into-type">{type}</div>
                    </div>
                    <div className="description">{description}</div>
                </div>
            </div>
        </>
    )
}
