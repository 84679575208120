import React from 'react';
import App from './App';
import Layout from './Layout';

import {Route} from 'react-router-dom';
// import Reflection from '../components/Reflection/Reflection';
import ARPet from '../components/ARPet/ARPet';
import IHelp from '../components/IHelp/IHelp';
import FoodFoundry from '../components/FoodFoundry/FoodFoundry';
import UIUX from '../components/UiUx/UiUxPage';
import MMF from '../components/MusicFestival/MusicFestival';
import Cresearch from '../components/CoffeeResearch/CoffeeResearch';
import FoodWaste from '../components/FoodWaste/FoodWaste';
import Starbuckss from '../components/Starbuckss/Starbuckss';
import RoyalBotanicGarden from '../components/RoyalBotanicGarden/RoyalBotanicGarden';
import AnimalVillage from '../components/AnimalVillage/AnimalVillage';
import AnimalVillageIntro from '../components/AnimalVillageIntro/AnimalVillageIntro';
// import PlayGround from '../components/PlayGround/PlayGround';
import GameMeet from '../components/GameMeet/GameMeet';

const Routes = (props) => {
  return(
    <>
      <Layout>
          {/* <Route path="/" exact render={()=><h1>Home</h1>}/>
              <Route path="/reflection" render={()=><h1>reflection</h1>}/> */}
              <Route path="/" exact component={App}/>
              <Route path="/uiux" exact component={UIUX}/>
              {/* <Route path="/xr" exact component={XR}/> */}
              <Route path="/animalvillage" exact component={AnimalVillage}/>
              <Route path="/animalvillageintro" exact component={AnimalVillageIntro}/>
              {/* <Route path="/playground" exact component={PlayGround}/> */}
              <Route path="/arpet" exact component={ARPet}/>
              <Route path="/ihelp" exact component={IHelp}/>
              <Route path="/foodfoundry" exact component={FoodFoundry}/>
              <Route path="/musicfestival" exact component={MMF}/>
              <Route path="/coffeeresearch" exact component={Cresearch}/>
              <Route path="/foodwaste" exact component={FoodWaste}/>
              <Route path="/startbucks" exact component={Starbuckss}/>
              <Route path="/royalbotanicgarden" exact component={RoyalBotanicGarden}/>
              <Route path="/gamemeet" exact component={GameMeet}/>
      </Layout>
    </>
  )
}

export default Routes;
