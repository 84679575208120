import React from 'react';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';
import './Layout.scss';

const Layout = props => {
    return (
        <div className="layout-container">
            <Navigation/>
            {props.children}
            <Footer/>
        </div>
    );
};


export default Layout;
