import React, { Component } from 'react';
import FoodWastePng from './FoodReduce.jpg';
import ReactPlayer from 'react-player'
import './FoodWaste.css';

class FoodWaste extends Component  {
    // state = {
    //     numPages: null,
    //     pageNumber: 1,
    //   }

    // onDocumentLoadSuccess = ({ numPages }) => {
    //     this.setState({ numPages });
    // }

    render() {
        // const { pageNumber, numPages } = this.state;
    return (
        <div>
            <div className="FoodWasteIntro">
                <img alt={"FoodWaste"}
                    src={FoodWastePng}
                    key="5"
                />
        
              {/* <Document
                file= {FoodWastePngPDF}
                renderInteractiveForms = {true}
                onLoadSuccess={this.onDocumentLoadSuccess}
                >
                <Page pageNumber={pageNumber} />
              </Document>  */}
              <div className="linkContainer">
                <div>
                    <h1>Living Test Record</h1>
                        <ReactPlayer
                            className="videoplayer"
                            url={`https://youtu.be/irKx9QP5sbg`}
                            config={{
                                youtube: {
                                playerVars: { showinfo: 1 } 
                                }
                            }}
                        />
                </div>
                <div>
                    <a target="_blank" rel="noopener noreferrer" href="https://projects.invisionapp.com/share/QERPG89JWM9#/screens/360045824_Loading_Page-3x"><h1>Invision Prototype</h1></a>
                </div>
              </div>
            </div>
        </div>
    );
   }
};

export default FoodWaste;