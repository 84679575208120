import React, { Component } from 'react'
import './uiuxWorkContainer.css';

import ARpet from './pics/ARPet.jpg';
import FoodFoundry from './pics/FoodFoundry.jpg';
import IHelp from './pics/IHelp.jpg';
import MusicFestival from './pics/MusicFestival.jpg';
import DesignCoffee from './pics/DesignCoffee.jpg';
import DesignFoodWaste from './pics/DesignFoodWaste.jpg';
import DesignStarbucks from './pics/DesignStarbucks.jpg';
import RoyalBotanicGarden from './pics/RoyalBotanicGarden.jpg';
import GameMeet from './pics/GameMeet.png';
import AnimalVillageImage from '../AnimalVillage/img/animalvillage_bg.jpg';
import {Link} from 'react-router-dom';

const images = {
    ARpet,
    IHelp,
    FoodFoundry,
    MusicFestival,
    DesignCoffee,
    DesignFoodWaste,
    DesignStarbucks,
    RoyalBotanicGarden,
    GameMeet,
    AnimalVillageImage
}

/*
style={{
    backgroundImage: `url(${images[content.img]})`,
    backgroundRepeat:`no-repeat`,
    backgroundSize:'cover',
    backgroundPosition:'center',
    borderRadius:'3% 3%',
  }}

*/

export default class uiuxWorkContainer extends Component {
    render() {
        const {id,name,time,type,description,img,link} = this.props;
        return (
            <>
                <div className="uiuxGrid">
                    {/* <img    alt={name}
                            src={images[img]}
                            key={id}
                            width="100%"
                            height="100%"
                            style={{
                            borderRadius:'3% 3%',
                            backgroundSize:'cover',
                            backgroundPosition:'center',
                        }}   
                    /> */}          
                    <Link to={link}>                              
                        {/* <a href={link}> */}
                        <div className="uiuxlayout shadow-3 grow bw2" 
                            style={{
                            backgroundImage: `url(${images[img]})`,
                            backgroundRepeat:`no-repeat`,
                            backgroundSize:'cover',
                            backgroundPosition:'left center',
                            borderRadius:'3% 3%',
                            }}>
                        </div>
                    </Link> 
                    <div className="Introuiux">
                        <div className="name">{name}</div>
                        <div className="Title">
                            <div className="time">{time} </div>
                            <div className="type">{type}</div>
                        </div>
                        <div className="description">{description}</div>
                    </div>
                </div>
            </>
        );
    }
}

// <div className="overlay">
//   <h3>{name}</h3>
//   <p>{type}</p>
// </div>
// </div>

