import React from 'react';
import './SectionDivider.scss';
import SectionImage from './sectionDivider.jpg';

function SectionDivider() {
    return (
        <div className="section-divider">
           <h1>What is more</h1>
        <img className="section-divider-img" alt="sectiondivider" src={SectionImage}/>
        <p>I like to learn by doing so I am interested in making design works and carrying out experiment on innovative notions.</p>
        </div>
    )
}

export default SectionDivider
