import React from 'react';
import '../FeatureWorkSection/FeatureWork.scss';
import Web from './Web.jpg';
import './WebSection.scss';

function WebSection() {
    return (
        <div className="WebSection-Container">
            <h1>Web Development</h1>
            <div className="WebSection-Intro">
            <img 
                src={Web}
                alt="WebWork"
            />
            </div>
            <p>React / Redux / React Native and ES6 make me love coding</p>
            <p className="moremarginBottom">When I start to dive into deep of Javascript, there are many interesting concept that encourages me to develop a number of applications.</p>
            <div className="button_link">
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/TingChiehLin"><span>Explore More</span></a>
            </div>
        </div>
    )
}

export default WebSection
