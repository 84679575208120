import React from "react";

const SvgCleancode = props => (
  <svg
    id="CLEANCODE_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 64 70"
    {...props}
    width="5em"
    height="5em"
  >
    <style>
      {
        ".CLEANCODE_svg__st0{fill:#f8f3f3}.CLEANCODE_svg__st1{fill:#63312d}.CLEANCODE_svg__st10{fill:#82d9ff}.CLEANCODE_svg__st11{fill:#f7db9b}.CLEANCODE_svg__st12{fill:#f9a287}.CLEANCODE_svg__st13{fill:#ffc8bb}.CLEANCODE_svg__st15{fill:#db785a}"
      }
    </style>
    <path
      className="CLEANCODE_svg__st15"
      d="M57.315 6.61h-3.88c.174.001.347.019.511.048.12.02.24.04.36.08a3.057 3.057 0 0 1 1.95 1.89c.1.3.16.64.16.98v32.829c0 1.66-1.34 3.001-3.001 3.001h-3.544v.003h7.444a3.584 3.584 0 0 0 3.584-3.584V10.194a3.584 3.584 0 0 0-3.584-3.584z"
    />
    <path
      className="CLEANCODE_svg__st1"
      d="M57.315 45.941h-7.444v-1h7.444c1.7 0 3.084-1.384 3.084-3.085V10.194c0-1.7-1.384-3.084-3.084-3.084h-7.444v-1h7.444a4.089 4.089 0 0 1 4.084 4.084v31.662a4.089 4.089 0 0 1-4.084 4.085z"
    />
    <path
      className="CLEANCODE_svg__st12"
      d="M56.256 8.629a3.057 3.057 0 0 0-1.95-1.89c-.12-.04-.24-.06-.36-.08-.17-.03-.35-.05-.53-.05H6.103A2.997 2.997 0 0 0 3.102 9.61v32.829c0 .1.01.21.02.31.01.14.03.28.07.42.05.23.14.45.25.65.14.28.33.53.55.74.155.158.329.291.514.41.032.02.065.035.098.054.176.104.362.187.559.253l.009.004v-.001c.294.097.602.161.931.161h47.313c1.66 0 3.001-1.34 3.001-3.001V9.609c0-.34-.061-.68-.161-.98z"
    />
    <path
      className="CLEANCODE_svg__st13"
      d="M56.257 8.629c-.28-.07-.63-.11-.93-.11H6.803c-.99 0-1.8.8-1.8 1.79v34.039c0 .33.07.64.17.93-.45-.14-.86-.39-1.18-.72-.22-.22-.41-.46-.55-.74a2.825 2.825 0 0 1-.32-1.07c-.01-.1-.02-.21-.02-.31V9.609c0-1.66 1.34-3.001 3.001-3.001h47.313c.18 0 .36.02.53.05.12.02.24.04.36.08.11.03.22.07.32.12.759.34 1.349.981 1.63 1.771z"
    />
    <path
      className="CLEANCODE_svg__st1"
      d="M53.418 45.941H6.102a3.504 3.504 0 0 1-3.501-3.501V9.61c0-1.93 1.57-3.5 3.501-3.5h47.316a3.504 3.504 0 0 1 3.501 3.5v32.83a3.504 3.504 0 0 1-3.501 3.501zM6.102 7.11a2.503 2.503 0 0 0-2.501 2.5v32.83a2.504 2.504 0 0 0 2.501 2.501h47.316a2.504 2.504 0 0 0 2.501-2.501V9.61c0-1.379-1.122-2.5-2.501-2.5H6.102z"
    />
    <path
      id="CLEANCODE_svg__XMLID_61_"
      className="CLEANCODE_svg__st0"
      d="M7.507 10.941h44.805V38.65H7.507z"
    />
    <path fill="#e1dddd" d="M9.411 12.868h42.901v-1.927H7.507V38.65h1.904z" />
    <path
      className="CLEANCODE_svg__st1"
      d="M52.312 39.15H7.506a.5.5 0 0 1-.5-.5V10.941a.5.5 0 0 1 .5-.5h44.805a.5.5 0 0 1 .5.5V38.65a.498.498 0 0 1-.499.5zm-44.306-1h43.805V11.441H8.006V38.15z"
    />
    <path
      id="CLEANCODE_svg__XMLID_59_"
      className="CLEANCODE_svg__st11"
      d="M25.421 45.441h11.251v7.617H25.421z"
    />
    <path
      id="CLEANCODE_svg__XMLID_58_"
      fill="#edcb85"
      d="M25.421 45.441h11.251v1.955H25.421z"
    />
    <path fill="#efc36a" d="M32.535 45.444h4.142v7.611h-4.142z" />
    <path fill="#dbab56" d="M32.535 45.444h4.142v1.955h-4.142z" />
    <path
      className="CLEANCODE_svg__st1"
      d="M36.673 53.558H25.421a.5.5 0 0 1-.5-.5v-7.616a.5.5 0 0 1 .5-.5h11.251a.5.5 0 0 1 .5.5v7.616a.499.499 0 0 1-.499.5zm-10.752-1h10.251v-6.616H25.921v6.616z"
    />
    <path className="CLEANCODE_svg__st1" d="M32.038 45.441h1v7.616h-1z" />
    <path
      className="CLEANCODE_svg__st12"
      d="M41.422 54.481c-.17-.57-.58-1.02-1.11-1.26a1.63 1.63 0 0 0-.48-.13c-.11-.02-.21-.03-.32-.03H19.617c-1.11 0-2.001.89-2.001 2.001v2.331h23.896v-2.331a1.826 1.826 0 0 0-.09-.581z"
    />
    <path
      className="CLEANCODE_svg__st13"
      d="M41.423 54.481H19.957c-.48 0-.86.38-.86.85v2.061h-1.48v-2.331c0-1.11.89-2.001 2.001-2.001h19.895c.11 0 .21.01.32.03.16.02.33.06.48.13.529.241.939.691 1.11 1.261z"
    />
    <path
      className="CLEANCODE_svg__st1"
      d="M41.508 57.889H17.612a.5.5 0 0 1-.5-.5v-2.33a2.504 2.504 0 0 1 2.5-2.501h19.896c1.379 0 2.5 1.122 2.5 2.501v2.33a.5.5 0 0 1-.5.5zm-23.396-1h22.896v-1.83c0-.828-.673-1.501-1.5-1.501H19.612c-.827 0-1.5.673-1.5 1.501v1.83z"
    />
    <path
      className="CLEANCODE_svg__st15"
      d="M42.483 53.058h-2.975c1.105 0 2.001.896 2.001 2.001v2.331h2.975v-2.331a2.002 2.002 0 0 0-2.001-2.001z"
    />
    <path
      className="CLEANCODE_svg__st1"
      d="M44.483 57.889h-2.976a.5.5 0 0 1-.5-.5v-2.33c0-.828-.673-1.501-1.5-1.501a.5.5 0 0 1 0-1h2.975a2.504 2.504 0 0 1 2.501 2.501v2.33a.5.5 0 0 1-.5.5zm-2.475-1h1.976v-1.83c0-.828-.673-1.501-1.501-1.501h-.977c.315.418.502.938.502 1.501v1.83z"
    />
    <path
      className="CLEANCODE_svg__st10"
      d="M22.76 30.686l-6.08-5.326a.962.962 0 0 1 0-1.448l6.08-5.327 1.269 1.447-5.254 4.603 5.254 4.602-1.269 1.449z"
    />
    <path
      className="CLEANCODE_svg__st1"
      d="M22.806 31.391l-6.456-5.655a1.462 1.462 0 0 1 0-2.2l6.456-5.656 1.928 2.199-5.201 4.557 5.201 4.556-1.928 2.199zm-.093-12.101l-5.704 4.998a.465.465 0 0 0 0 .696l5.704 4.997.609-.695-5.308-4.649 5.308-4.65-.609-.697z"
    />
    <path
      className="CLEANCODE_svg__st10"
      d="M37.059 30.686l6.08-5.326a.962.962 0 0 0 0-1.448l-6.08-5.327-1.269 1.447 5.254 4.603-5.254 4.602 1.269 1.449z"
    />
    <path
      className="CLEANCODE_svg__st1"
      d="M37.012 31.391l-1.928-2.199 5.202-4.556-5.202-4.557 1.928-2.199 6.456 5.656a1.464 1.464 0 0 1 0 2.201l-6.456 5.654zm-.516-2.106l.609.695 5.704-4.997a.462.462 0 0 0 0-.695l-5.704-4.999-.609.695 5.308 4.65-5.308 4.651z"
    />
    <path
      transform="rotate(-61.079 29.775 24.795)"
      className="CLEANCODE_svg__st10"
      d="M21.307 23.834h16.934v1.924H21.307z"
    />
    <path
      className="CLEANCODE_svg__st1"
      d="M26.721 33.353l-2.56-1.413 8.666-15.701 2.561 1.413-8.667 15.701zm-1.201-1.806l.809.446 7.7-13.949-.809-.446-7.7 13.949z"
    />
    <circle className="CLEANCODE_svg__st11" cx={52.172} cy={42.181} r={8.705} />
    <path
      className="CLEANCODE_svg__st1"
      d="M52.172 51.386c-5.076 0-9.205-4.129-9.205-9.205s4.129-9.205 9.205-9.205 9.205 4.129 9.205 9.205-4.129 9.205-9.205 9.205zm0-17.41c-4.524 0-8.205 3.681-8.205 8.205s3.681 8.205 8.205 8.205 8.205-3.681 8.205-8.205-3.681-8.205-8.205-8.205z"
    />
    <path
      className="CLEANCODE_svg__st0"
      d="M56.557 39.722l-1.464-1.464-4.094 4.094-1.748-1.748-1.464 1.464 3.21 3.211.003-.003.002.001z"
    />
    <path
      className="CLEANCODE_svg__st1"
      d="M51.002 45.777l-.04-.002a.492.492 0 0 1-.318-.144l-3.211-3.21a.5.5 0 0 1 0-.708l1.465-1.465a.5.5 0 0 1 .707 0L51 41.643l3.74-3.74a.504.504 0 0 1 .708 0l1.464 1.465a.5.5 0 0 1 0 .707l-5.555 5.555a.499.499 0 0 1-.355.147zm-2.509-3.709l2.506 2.505 4.851-4.851-.757-.758-3.74 3.74a.5.5 0 0 1-.707 0l-1.395-1.395-.758.759z"
    />
  </svg>
);

export default SvgCleancode;