import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5em"
      height="5em"
      viewBox="0 0 113 120"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-300 -273)">
          <g transform="translate(300 273)">
            <path
              fill="#F7BB83"
              d="M45.945 86.238L23.483 109.606 7.661 104.613 3.321 90.904 86.555 7.671 96.434 17.549 105.534 26.649 75.251 56.932"
            ></path>
            <path
              fill="#324654"
              d="M79.472 55.712a2.998 2.998 0 01-2.121-5.121l29.417-29.417a2.998 2.998 0 014.242 0 2.998 2.998 0 010 4.242L81.593 54.833a2.991 2.991 0 01-2.121.879zM4.504 92.721A2.998 2.998 0 012.383 87.6L87.789 2.194a2.998 2.998 0 014.242 0 2.998 2.998 0 010 4.242L6.625 91.842a2.988 2.988 0 01-2.121.879zM23.483 111.7a2.998 2.998 0 01-2.121-5.121l27.52-27.52a2.998 2.998 0 014.242 0 2.998 2.998 0 010 4.242l-27.52 27.52a2.988 2.988 0 01-2.121.879z"
            ></path>
            <path
              fill="#FFF"
              d="M79.471 52.712L101.297 74.538 80.42 82.129 72.829 103.007 51.003 81.18"
            ></path>
            <path
              fill="#324654"
              d="M72.829 106.007c-.787 0-1.553-.31-2.122-.879L48.881 83.302a3 3 0 014.243-4.242l18.495 18.495 5.982-16.451a2.998 2.998 0 011.794-1.794l16.45-5.981L77.35 54.833a2.998 2.998 0 010-4.242 2.998 2.998 0 014.242 0l21.826 21.826a3 3 0 01-1.096 4.941L82.761 84.47l-7.113 19.563a2.996 2.996 0 01-2.819 1.974z"
            ></path>
            <path
              fill="#FFF"
              d="M59.729 33.245L31.264 61.696s2.486 6.235 7.923 11.798c6.366 6.513 11.815 7.686 11.815 7.686L79.47 52.711S72.589 50 67.436 44.993c-5.193-5.047-7.707-11.748-7.707-11.748z"
            ></path>
            <path
              fill="#324654"
              d="M51.003 83.18c-.14 0-.281-.015-.421-.045-.246-.053-6.114-1.38-12.824-8.243-5.652-5.782-8.244-12.186-8.351-12.456a1.999 1.999 0 01.444-2.155L58.315 31.83a2 2 0 013.286.713c.023.061 2.438 6.359 7.23 11.017 4.81 4.674 11.308 7.266 11.373 7.292a1.999 1.999 0 01.681 3.274L52.417 82.595a1.998 1.998 0 01-1.414.585zM33.658 62.132c.965 1.987 3.248 6.166 6.96 9.964 4.269 4.367 8.097 6.166 9.829 6.813L76.024 53.33c-2.601-1.307-6.612-3.629-9.98-6.901-3.373-3.278-5.665-7.166-6.948-9.724L33.658 62.132z"
            ></path>
            <path
              fill="#F7BB83"
              d="M49.254 51.287L54.47 53.056 58.884 49.762 58.813 55.269 63.31 58.449 58.051 60.084 56.416 65.343 53.236 60.846 47.729 60.917 51.022 56.503z"
            ></path>
            <path
              fill="#324654"
              d="M55.187 68.7c-.643 0-1.254-.31-1.632-.845l-2.573-3.638-4.456.057c-.746-.024-1.462-.413-1.808-1.092a2.002 2.002 0 01.179-2.104l2.665-3.571-1.431-4.22a2 2 0 012.536-2.538l4.221 1.432 3.571-2.665a2 2 0 013.196 1.628l-.057 4.457 3.638 2.572a2 2 0 01-.561 3.543l-4.255 1.322-1.323 4.255a2 2 0 01-1.91 1.407zm-3.18-8.497c.649 0 1.258.314 1.633.845l.84 1.188.432-1.39a1.998 1.998 0 011.316-1.316l1.389-.432-1.188-.84c-.538-.38-.854-1-.845-1.658l.019-1.456-1.166.87a2.002 2.002 0 01-1.838.292l-1.379-.468.467 1.378a2 2 0 01-.291 1.839l-.871 1.166 1.456-.019a.235.235 0 00.026.001z"
            ></path>
            <path
              fill="#FFF"
              d="M109.13 23.052c1.757-1.758-1.092-7.457-6.365-12.73-5.273-5.272-10.973-8.123-12.73-6.365 0 0-.034 6.663 6.466 13.163s12.629 5.932 12.629 5.932z"
            ></path>
            <path
              fill="#324654"
              d="M109.13 26.052a3.001 3.001 0 01-2.487-4.679c-.132-1.231-1.906-4.837-6-8.931-4.093-4.093-7.699-5.866-8.93-5.999a3.003 3.003 0 01-3.8-.366 2.998 2.998 0 010-4.242c3.914-3.916 11.589.982 16.973 6.365 5.383 5.383 10.279 13.058 6.366 16.973a2.993 2.993 0 01-2.122.879z"
            ></path>
            <path
              fill="#324654"
              d="M107.648 25.57c-1.138 0-2.597-.344-4.46-1.275-2.592-1.296-5.505-3.496-8.203-6.194-2.698-2.697-4.898-5.611-6.194-8.203-2.073-4.147-1.237-6.289-.171-7.355a2 2 0 012.901 2.751c-.213.924 1.24 4.928 6.292 9.98 5.05 5.05 9.056 6.494 9.979 6.292a1.999 1.999 0 012.751 2.901c-.587.587-1.499 1.103-2.895 1.103zM20.559 112.619c-4.284 0-9.805-2.503-14.181-6.879-6.927-6.928-7.471-14.664-3.995-18.14a3 3 0 014.244 4.242c-.536.609-.916 4.744 3.996 9.656 4.818 4.818 9.79 5.443 10.829 4.996a3 3 0 014.155 4.327c-1.224 1.222-2.994 1.798-5.048 1.798z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
