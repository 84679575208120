import React from 'react';
import ReactPlayer from 'react-player'
// import UIUXDesignLayout from "../IntroLayout/IntroLayout";
import './Starbuckss.css';
import Book from "./book.png";
import EmapthyMap from './EmapthyMap.png';
import UserFlow from './UserFlow.png';
import App from "./App.png";
import Hat1 from './hat1.png';
import Hat2 from './hat2.png';

const Starbuckss = () => {
    return (
        <div className="Intro">
                <div className="StarbuckssIntro">
                    <div className="first bottom">
                        <h1>Starbucks Case Study</h1>
                        <div className="StarbucksCaseStudy">
                        <p>Starbucks coffee shops are ubiquitous. Most people enjoy drinking unique taste. However, sometimes people have to wait for a
                        long queue and they might get wrong coffee when the staff call their name. Our team realized that we should improve shopping experience in order to maintain happy environment.
                        </p>
                        </div> 
                    </div>
                    <div className="second">
                        <h1>Problem & Situation</h1>
                        <ol>
                            <li>If the queue is too long, people feel annoyed or start to do other things, such as using smartphone and chatting with friends.</li>
                            <li>There are too many people on the weekend. Thus, we observe that people see each other in front of desk when their name is called and consider whether they get the certain coffee by themselves</li>
                        </ol>
                    </div>
                    <div className="user">
                        <h1>The understanding of user's reactions</h1>
                        <img
                            src={EmapthyMap}
                        />
                    </div>
                    <br/>
                    <div className="book">
                        <h1>Ideation</h1>
                        <p>My idea is that I design a daily story paper for everyone to read when they enter the starbuck and 
                            they will get a story from different types, such as the science & fiction and romantic story.
                        </p>
                        <img
                            src={Book}
                        />
                    </div>
                    <div className="third">
                        <div className="statbuckprototype">
                            <div className="statbuckprototype-item">
                                {/* <a target="_blank" rel="noopener noreferrer" href="https://marvelapp.com/c02g21e/screen/57764551">Marvel Prototype</a>                         */}
                                <h1>App Solution</h1>
                                <p>My team decide to make an App because most people are able to </p>
                                <img
                                    className="prototype-item-image startbuckimage"
                                    src={App}
                                    
                                />
                            </div>
                            <div>
                                <h1>User Flow</h1>
                                    <img
                                        className="prototype-item-image userflow"
                                        src={UserFlow}
                                    />
                            </div>
                        </div>
                    </div>
                    <h1>Classification hat</h1>
                    <div className="hat">
                        <img
                            src={Hat1}
                                  
                        />
                        <img
                            src={Hat2}
                                   
                        />
                    </div>
                    <br/>
                    <div className="videopIntro">
                        <div className="player-wrapper">
                            <h1>User Test Record</h1>
                            <div>
                                <ReactPlayer
                                    url={`https://youtu.be/dh6htXHrl-Y`}
                                    config={{
                                        youtube: {
                                        playerVars: { showinfo: 1 } 
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="player-wrapper">
                            <h1>How Our App Work</h1>
                            <div>
                                <ReactPlayer
                                    className='react-player'
                                    url={`https://youtu.be/SvJvb-VI7WA`}
                                    config={{
                                        youtube: {
                                        playerVars: { showinfo: 1 } 
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="summary">
                        <h1>Summary</h1>
                        <div className="summarycontainer">
                            <div className="summary-text">
                                <h2>Test Feedback</h2>
                                <ol> 
                                    <li>The user see the <span style={{color:'blue'}}>“Log in”</span> page and does not see clearly font because they are too small, When they went to <span style={{color:'blue'}}>"Home”</span> page, He did not see what they are difference between <span style={{color:'blue'}}>“mean”</span> and <span style={{color:'blue'}}>“order online”</span> button. Moreover,
                                         He does not know how to go back the last page and spent a lot of time searching.
                                    </li>
                                    <li>The order page is too narrow. There are a lot of items that closed to each other. When I completed the order, I do not understand what the number means which is <span style={{color:'blue'}}>“32”</span>.</li>
                                    <li>The hamburger menu does not work. </li>
                                </ol>
                            </div>
                            <div className="summary-description">
                                <h2>What I have improved our App’s process</h2>
                                <p>
                                After discussion, we realized that we should add more functions, such as history record, back to the previous page, ordering more different food not just only coffee.
                                </p>
                                <p>
                                After that, we develop more pages which are related to user expectations. They are able to switch different page easily and understand what happened to each page.
                                </p>
                                <p>
                                Furthermore, not only do customers more choices to purchase food, but they are also to have clear directions that lead them how to do.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
        </div>
    );
};

export default Starbuckss;