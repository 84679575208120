import React from 'react';
// import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
// import CSSTransitionGroup from 'react-addons-css-transition-group'; 
import './Navigation.css';

import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
// import Button from '@material-ui/core/Button';

import {Link} from 'react-router-dom';
import MyLogo from './TCLLogo';

import {scroller} from 'react-scroll';
import SwipeDrawer from '../Drawer/SwipeDrawer';
import Submenu from '../Submenu/Submenu';

const scrollToElement = (element) => {
    scroller.scrollTo(element, {
        duration: 1000,
        delay: 100,
        smooth: true,
        offset: -100
    });
}

class Navigation extends React.Component {

    state = {
        headerShow: true,
        shrinkMenu: false,
        fontSizeShrink: 36,
        opacity: 1,
        showAboutMenu: false,
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleHover = (event) => {
        this.setState({showAboutMenu: true});
    };

    handleLeave = (event) => {
        this.setState({showAboutMenu: false});
    };

    handleScroll = () => {
        if (window.scrollY <= 110) {
            this.setState({
                shrinkMenu: false,
            })
        } else if (window.scrollY > 110) {
            this.setState({
                shrinkMenu: true,
            })
        }
    }

    render() {
        const fontclasses = ["fontSize"];
        const hoverAnimation = "hover-underline-animation";
        const opacity = ["AppBar"];
        const NavigationBar = "NavigationBar";
        const navMenuItem = "nav__menu-item";

        if (this.state.shrinkMenu) {
            fontclasses.push("shrink");
            opacity.push("shrink");
        }

        // let logowidth,logoheight;

        return (
            <div>
                {/* {this.state.shrinkMenu ?  */}
                <div>
                    <AppBar position="fixed"
                            style={{
                                backgroundColor: '#151A26',
                                boxShadow: 'none',
                                padding: '10px 0',
                            }}
                            className={`${NavigationBar} ${opacity.join(" ")}`}

                    >
                        <ToolBar style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <div className="header_Logo">
                                <MyLogo
                                    shrink={this.state.shrinkMenu}
                                    link={true}
                                    linkTo="/"
                                />
                            </div>
                            <div className="NavigationBar">
                                <nav className="nav">
                                    <ul className="nav__menu">
                                        <li className={`${fontclasses.join(" ")} ${navMenuItem} ${hoverAnimation}`}
                                            onClick={() => scrollToElement("Web")}>
                                            <Link to="/">
                                                Web
                                            </Link>
                                        </li>
                                        <li className={`${fontclasses.join(" ")} ${navMenuItem} ${hoverAnimation}`}
                                            onClick={() => scrollToElement("FeatureWork")}>
                                            <Link to="/">
                                                VR / AR
                                            </Link>
                                        </li>
                                        <li className={`${fontclasses.join(" ")} ${navMenuItem} ${hoverAnimation}`}
                                            onClick={() => scrollToElement("UIUX")}>
                                            <Link to="/">
                                                UI / UX
                                            </Link>
                                        </li>
                                        {/* <li className={`${fontclasses.join(" ")} ${navMenuItem} ${hoverAnimation}`} onClick={()=>scrollToElement("XR")}>
                                        <Link to="/xr">
                                          XR
                                        </Link>                                
                                      </li> */}
                                        {/* <li className={`${fontclasses.join(" ")} ${hoverAnimation} ${navMenuItem}`}
                                            onClick={() => scrollToElement('Playground')}>
                                            <Link to="/">
                                                Playground
                                            </Link>
                                        </li> */}
                                        <li className={`${fontclasses.join(" ")} ${navMenuItem} ${hoverAnimation}`}
                                            onClick={() => scrollToElement("About")}>
                                            <Link to="/">
                                                About
                                            </Link>
                                        </li>
                                        {/* <li className={`${fontclasses.join(" ")} ${hoverAnimation} ${navMenuItem}`}>
                                        <Link to="/project">
                                           <a>Project</a>
                                        </Link>  
                                        <Submenu/> 
                                      </li> */}
                                        {/* <div className="submenu-container">
                                        <CSSTransitionGroup
                                          transitionName="slide"
                                          transitionEnterTimeout={300}
                                          transitionLeaveTimeout={300}
                                        >
                                          { this.state.showAboutMenu && <Submenu /> }
                                        </CSSTransitionGroup>
                                      </div> */}
                                    </ul>
                                </nav>

                                {/* <Link to="/magiworks">
                                <Button size="large" style={{textTransform: 'none',color:'white'}}><span className={fontclasses.join(" ")}>Award</span></Button>
                              </Link> */}
                                {/* <Link to={{
                                pathname:'/reflection'
                              }}>
                                <Button size="large" style={{textTransform: 'none',color:'white'}}><span className={`${fontclasses.join(" ")} ${hoverAnimation}`}>Reflection</span></Button>
                              </Link> */}
                                {/* <Button onClick={()=>scrollToElement("contact")} size="large" style={{textTransform: 'none',color:'white'}}><span className={`${fontclasses.join(" ")} ${hoverAnimation}`}>Contact</span></Button>       */}
                            </div>
                            <div className="DrawerNav">
                                <SwipeDrawer/>
                            </div>
                        </ToolBar>
                    </AppBar>

                </div>

                {/* : null} */}


                {/* //Mobile Model */}
                {/* <Drawer/> */}
            </div>

        );
    }
}

export default Navigation;
