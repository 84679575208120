import React from 'react'
import './AnimalVillageIntro.scss';
import VRARMarket from './img/VRARMarketing.jpg';
import Theme from './img/whole_game.jpg';
import Concept_1 from './img/Concept_1.jpg';
import Concept_2 from './img/Concept_2.jpg';
import Concept_3 from './img/Concept_3.jpg';
import GameFlowLogic from './img/GameFlowLogic.jpg';
import Grab from './img/grab.jpg';
import Level_Design from './img/level_Design.jpg';
import Moodboard from './img/Moodboard.jpg';
import Teleporation from './img/teleporation.jpg';

import StoryBoard_1 from './img/1_startMenu.jpg';
import StoryBoard_2 from './img/2_SeeAnimals.jpg';
import StoryBoard_3 from './img/3_Move.jpg';
import StoryBoard_4 from './img/4_Grabananimal.jpg';
import StoryBoard_5 from './img/5_move tohouse.jpg';
import StoryBoard_6 from './img/6_seeawolf.jpg';
import StoryBoard_7 from './img/7_picka rake.jpg';
import StoryBoard_8 from './img/8_attack.jpg';
import StoryBoard_9 from './img/9_win.jpg';

function AnimalVillage() {
    return (
        <>
            <div className="AnimalVillage-intro-container">
                <h1 className="AnimalVillage-intro-title">Alleviate stress away from COVID-19</h1>
            </div>
            <div className="AnimalVillage-Container">
                <div className="section-line">INTRODUCTION</div>
                <section className="content-container paddingTopZero">
                    <div className="content-container-center">
                        <h1>Animal Village</h1>
                        <p className="marginBottom">
                        The last semester, I decided to research a popular topic which is Virtual Reality (VR).  This is amazing and immersive experience for people who are able to interact with virtual world. I am going to implement design methodologies in terms of game design and test. Next, using cutting edge technology which is Oculus Quest create a virtual environment so that users are not only capable of living immersive virtual world, but they are also able to do more engagements.
                        </p>
                        <h1>Project Overview</h1>
                        <h3>The Objectives</h3>
                        <p>My projects are the main focus on immersive experience with advanced technology in VR to support players to release relieve stress, do exercise anywhere and make players interested in the playful game by a variety of challenge design which contains to save animals, defeat enemies coming. A group of audiences is focus on ages between ages 20s to 35.
                        </p>
                        <h3>Concept</h3>
                        <p>The project encourages people to play the game; thus, when they do exercise with high motivation and engagements environment to active them. I design VR experience more playful by adding animals and interactive elements to play with them.</p>
                        <h3>Scope</h3>
                        <p className="marginBottom">Building on Oculus Quest, I might need to develop an interactive game. However, I need 3D assets to support my project within two months time limitation and pressure.
                        </p>
                        <h1>Resources & Tools</h1>
                        <ul>
                            <li>Unity3D to Develop a VR App with Oculus Quest</li>
                            <li>Sketch for Moodboard, Design Interface and wireframe</li>
                            <li>3D assets from Unity3D Store</li>
                        </ul>
                        <h1>Team Members</h1>
                        <p>Ting Chieh Lin - Design & Develop</p>
                    </div>
                </section>
                <div className="section-line">MARKETING & ANALYSIS</div>
                <section className="two-column-container">                    
                    <div className="two-column-container-left">
                        <h1>Trend</h1>
                        <p className="marginBottom">
                            Virtual Reality is a huge and highly grow market every year. Advanced technology has been used a variety of areas, such as education, training, traveling and  medical industries. According to Digi-Capital (2017) reports that VR/ AR market size is dramatic increasing to at least $1500 million more.
                        </p>
                        <p className="marginBottom">
                            Moreover, VR motivates players to explore unknown elements. Therefore, users are able to be curious about trying something new and do more engagements with interactive experience. Greengard (2019) described that “Immersive virtual-­reality gaming is also advancing. In Catan VR, for example, the popular board game becomes a rich interactive 3D experience. Objects, characters, and avatars emerge from the virtual board, scurry about, and engage in a variety of actions and activities.”
                        </p>
                    </div>
                    <div className="two-column-container-right">                     
                        <img alt="" src={VRARMarket}/>
                    </div>
                </section>
                <div className="section-line">DESIGN CHALLENGE & SOLUTION</div>
                <section className="content-container">
                    <div className="content-container-center">
                        <h1>More fun, No Stress !</h1>
                        <p className="marginBottom">
                        People stay home and might have been suffering from depression due to the novel coronavirus. I decided to make a game in order to make people laughs. The Animal village includes playful and encouraged game design elements; thus whenever players enter a virtual world, they feel relaxed and release their pressure from work during the novel coronavirus.  
                        </p>
                        <h1>Hypothesis</h1>
                        <ul>
                            <li>How might we design hilarious reactions and interactions in the game?</li>
                            <li>How might we provide playful design elements so players continue to play the game?</li>
                            <li>How might we assist people who are suffering from depression by a variety of interactions during the game?</li>
                            <li>How might we encourage people to challenge by themselves in order to achieve high results and play the game again?</li>
                        </ul>
                    </div>
                </section>
                <div className="section-line">Design</div>
                <section className="one-column-container">
                    <h1>Story</h1>
                    <p className="marginBottom">Jay is born in quiet and peace countryside. He got a part-time job in the farm to take care of naughty animals. Someday, wild animals invade their farm. Jay is brave to defend the farm against ferocious animals using a number of left weapons to get them out away from farm.</p>
                </section>
                <section className="two-column-container">                    
                    <div className="two-column-container-left">
                        <h1>Theme</h1>
                        <p className="marginBottom">The picture represent how the gamer play Animal Village.</p>
                        <img alt="" src={Theme}/>
                    </div>
                    <div className="two-column-container-right">                    
                        <h1>Moodboard</h1>
                        <p className="marginBottom">The theme is not complicated and casual game so the style is designed for low-ploy texture. Fresh trees are located around the farm. There are a number of straw and crop are surrounded.</p>
                        <img src={Moodboard}/>
                    </div>
                </section>
                <section className="content-container">
                    <div className="content-container-left">
                        <h1>Game Concept Design</h1>
                        <p className="marginBottom">You are in the intensive time and excited game to save animals in order to avoid enemies invading their village. You are comfortable and easy to interact with virtual environment throughout the game, the difficulty is increasing all the time until at the end to the game.</p>
                        <img style={{width:"90%"}} src={Concept_1}/>
                    </div>
                    <div className="content-container-center">
                        <h1>How a gamer defend an enemy</h1>
                        <p className="marginBottom">In the game, You pick a weapon near the environment to beat enemies so that they are able to wing arms to do exercise.</p>
                        <img style={{width:"90%"}} src={Concept_2}/>
                    </div>
                    <div className="content-container-right">
                        <h1>Grab an animal</h1>
                        <p>Moreover, when you save animals, you are also to see different hilarious behaviors to present in front of players.</p>
                        <img style={{width:"90%"}} src={Concept_3}/>
                    </div>
                </section>
                <h1 className="section-line">StoryBoard</h1>
                <section className="content-container">
                    <div className="content-container-left">
                        <p className="marginBottom">When users start a game. The first thing they will see is “Main menu”</p>
                        <img className="" style={{width:"90%"}} src={StoryBoard_1}/>
                    </div>
                    <div className="content-container-center">
                        <p className="marginBottom">The player search for animals</p>
                        <img style={{width:"90%"}} src={StoryBoard_2}/>
                    </div>
                    <div className="content-container-right">
                        <p className="marginBottom">The player is able to use his left controller to move a certain destination</p>
                        <img style={{width:"90%"}} src={StoryBoard_3}/>
                    </div>
                </section>
                <section className="content-container">
                    <div className="content-container-left">
                        <p className="marginBottom">To grab an animal in order to save it</p>
                        <img style={{width:"90%"}} src={StoryBoard_4}/>
                    </div>
                    <div className="content-container-center">
                        <p className="marginBottom">This is where the user put the animal in the zone and he is able to gain score</p>
                        <img style={{width:"90%"}} src={StoryBoard_5}/>
                    </div>
                    <div className="content-container-right">
                        <p className="marginBottom">However, opponents will come to stop the player doing their tasks easily</p>
                        <img style={{width:"90%"}}  src={StoryBoard_6}/>
                    </div>
                </section>
                <section className="content-container">
                    <div className="content-container-left">
                        <p className="marginBottom">The gamer is able to grab a weapon to defeat enemies</p>
                        <img style={{width:"90%"}} src={StoryBoard_7}/>
                    </div>
                    <div className="content-container-center">
                        <p className="marginBottom">They can hit and wave their weapon so enemies will be killed</p>
                        <img style={{width:"90%"}} src={StoryBoard_8}/>
                    </div>
                    <div className="content-container-right">
                        <p className="marginBottom">Within time limit, a player do the best to save animals. The result will be shown at the end and the player can decided to play the game again</p>
                        <img style={{width:"90%"}} src={StoryBoard_9}/>
                    </div>
                </section>
                <section className="two-column-container">
                    <div className="content-container-left">
                        <h1>Goal</h1>
                        <p>The player have to save animals by grabbing them into safety area within limited time. As a result, they can gain different score.</p>
                        <p className="marginBottom">A gamer defend enemies by hand weapon or gun to block more enemies coming.</p>
                    </div>
                    <div className="content-container-right">
                        <h1>Rule</h1>
                        <p className="marginBottom">Within 12 minutes, the gamer protect their home from enemies killing their animals. The player can use different weapons or guns to defeat them.</p>
                    </div>
                </section>
                <section className="two-column-container">
                <div className="content-container-left">
                        <h1>Level Design</h1>
                        <p>There are many upcoming waves depending on time and difficulties.</p>
                        <ul>
                            <li><span className="Bold">Wave 1:</span> It is only 5 animals around character to grab them into the house.</li>
                            <li><span className="Bold">Wave 2:</span> The player is able to see the weapon and pick it up to beat an enemy. 5 x Animals , 2 x Wolves.</li>
                            <li><span className="Bold">Wave 3:</span> The player start to feel exciting when they see two enemies coming and he or she has to grab animals back to home. 5 x Animals, 3 x wolves.</li>
                            <li><span className="Bold">Wave 4:</span> With a strong weapon which is a gun to exists this level. They are capable of using it to kill it. 5 x Animals , 2 x Wolves, 2 x Bears.</li>
                            <li><span className="Bold">Wave 5:</span> This is Challenge level because the player either use a weapon or fun to defend enemies coming. 5 Animals, 2 x Bears, 2 x Gorilla.</li>
                        </ul>
                </div>
                <div className="content-container-right">
                    <img className="center" alt="Wave 5" style={{width:"100%"}} src={Level_Design}/>
                </div>
                </section>
                {/* <section className="content-container">
                    <div className="content-container-center">
                        <h1>Game Flow Logic</h1>
                        <p className="marginBottom">
                        
                        </p>
                        <img className="center marginBottom" alt="game glow" style={{width:"50%"}}src={GameFlowLogic}/>
                    </div>
                </section> */}
                <div className="section-line">USER INTERACTION</div>
                <section className="two-column-container">                    
                    <div className="two-column-container-left">
                        <h1>Grab</h1>
                        <p className="marginBottom">The theme is not complicated and casual game so the style is designed for low-ploy texture. Fresh trees are located around the farm. There are a number of straw and crop are surrounded.</p>
                        <img alt="grab" style={{width:"80%",height:""}} src={Grab}/>
                    </div>
                    <div className="two-column-container-right">                    
                        <h1>Movement</h1>
                        <p>The left control can have two ways of moving in the game. The player just press <span className="Bold">“joystick”</span> to switch “formal” or “Teleportation”.</p>
                        <p>Users are able to move by their left controller so they press forward "joystick" button to move the certain location with blue light.</p>
                        <p>If they want to look around the environment, they are able to use "joystick" on their right hand controller.</p>
                        <p className="marginBottom">When they are not satisfy score, they can press <span className="Bold">"Menu"</span> button to go back playing again.</p>
                        <img alt="telporation" src={Teleporation}/>
                    </div>
                </section>
                <div className="section-line">SUMMARY</div>
                <section className="content-container paddingTopZero">
                    <div className="content-container-center">
                        <h1>Brief</h1>
                        <p className="marginBottom">
                            Animal Village contains playful and encouraged game design with visual effects in the game in order to resolve my research problem which is related to people who stay home for relaxation. My interest is to play video games; therefore, how might we design a game for people to play with. 
                        </p>
                        <p className="marginBottom">
                        According to Animal Village, people are glad to experience to grab animals experience and feel playful because I observe  audiences and players who are comfortable to play the game. I believe that Animal village is able to help people who are suffer from depression and fear of the novel coronavirus at home. 
                        </p>
                        <h1>Next Plan</h1>
                        <p>
                        In the future, I hope that I am able to add multi-players elements; thus, players are capable of assist each other to deal with challenges. As a result, the game enables to play with families or parties.
                        </p>
                    </div>
                </section>
            </div>
        </>
    )
}

export default AnimalVillage
