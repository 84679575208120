import React from 'react'
import './GameMeet.scss';
import GameMeetImg from './GameMeet.png';

function GameMeet() {
    return (
        <div className="gamemeet-container">
            <h1>GameMeet</h1>
            <img src={GameMeetImg} alr="img" style={{width:"1024px", hgeght:'768px'}}/>
            <div className="gamemeet-intro">
                <div>Gaming industries are growing. I am a gamer and I love playing games. However, I find it difficult to find a player to play with me so why we shoud have </div>
                <div className="button_link">
                    <a target="_blank" rel="noopener noreferrer" href="https://gamemeet.vercel.app/"><span>Explore Site</span></a>
                </div>
            </div>
        </div>
    )
}

export default GameMeet
