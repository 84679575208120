import React from 'react';
import './RoyalBotanicGarden.scss';
import IOTDesign from './img/IOT.jpg';
import Infinitymap from './img/infinitymap.jpg';
import Journeymap from './img/JourneyMap.jpg';
import Wireflow from './img/wireflow.jpg';
import StyleTiles1 from './img/StyleTiles1.jpg';
import StyleTiles2 from './img/StyleTiles2.jpg';
import Moodboard1 from './img/Moodboard1.jpg';
import Moodboard2 from './img/Moodboard2.jpg';
import Appanalysis from './img/Appanalysis.jpg';
import Personapic from './img/personaimg.jpg';
import ReactPlayer from 'react-player';

const RoyalBotanicGarden = () => {

    // const [isYoutubevideo, setisYoutubevideo] = useState(true);

    // function setYoutubeState() {
    //     setisYoutubevideo((prevCount) => {
    //         !prevCount;
    //     });
    // }

    return (
        <>
            <div className="Royal-intro-container">
                <h1 className="Royal-intro-title">Meet with Plant Lovers and Leran Effectively !</h1>
            </div>
            <div className="royal-container">
                <div className="section-line">INTRODUCTION</div>
                <section className="content-container paddingTopZero">
                    <div className="content-container-center">
                        <h1>Royal Botanic Garden</h1>
                        <p className="marginBottom">
                            The app is designed for people who are plant lovers and interested in learning a variety of
                            plants. At the beginning, a group of my teammates do survey and interview with people in the
                            royal botanic garden. After that, individuals
                            start to work own project.
                        </p>
                        <h1>Challenge</h1>
                        <p>I like nature so I went to the Royal Botanic Garden in Melbourne.
                            However, there are too many problems when I interviewed with vistors. People said that I
                            enjoy going to the garden and get fresh-air but
                            I spend too much time dealing with these issues.
                        </p>
                        <ul className="marginBottom">
                            <li>I can not find ways to go my destinations</li>
                            <li>I do not know plants' name</li>
                            <li>There are not interesting event and workshops</li>
                        </ul>
                        <h1>Plan to Do</h1>
                        <ul>
                            <li>Interview</li>
                            <li>User research & Analysis</li>
                            <li>Persona</li>
                            <li>Scenario</li>
                            <li>Journey Map</li>
                            <li>Wireflow</li>
                            <li>UI Analysis</li>
                            <li>Moodboard</li>
                            <li>UI Design & Prototyping</li>
                            <li>Usability Testing</li>
                            <li>Unity3D Development</li>
                        </ul>
                        <h1>Resources & Tools</h1>
                        <ul>
                            <li>Miro for Brain Storming and User Analysis</li>
                            <li>Sketch for Design User Interface</li>
                            <li>Unity3D to Develop an App with AR Pototype</li>
                        </ul>
                        <h1>Team Members</h1>
                        <p>Ting Chieh Lin - Design & Develop</p>
                    </div>
                </section>
                <div className="section-line">USER RESEARCH & ANALYSIS</div>
                <section className="content-container">
                    <div className="content-container-left">
                        <h1>Hypothesis</h1>
                        <p className="marginBottom">
                            Vistors do exercise and walk into a famous royal botanic garden in Melbourne. They discover
                            a variety of plants and are willing to learn by activities and workshops.
                            However, they got lost and look for help to leave from the royal botanic garden.
                        </p>
                        <h3 className="marginBottom">"I believe that developing an app with cutting-edge technology
                            might assist vistors to resolve problems and improve more engagement and fun."</h3>
                    </div>
                    <div className="content-container-center">
                        <h1>Survey</h1>
                        <p>
                            What I got <span className="bold">8</span> people responses is most vistors went to the
                            Royal botanic garden have <span className="bold">3</span> problems. They find it hard to
                            find ways to arrive at their destinatons.
                        </p>
                        <p>
                            Moreover, there are too many types of plants and people do not know how to call it.
                        </p>
                        <p>
                            Furthermore, they suggest whether they are able to participate workshops with their family
                            and friends.
                        </p>
                        <h3>Result</h3>
                        <ul>
                            <li><span className="bold">85%</span> vistors said that they usually go with family or
                                friends.
                            </li>
                            <li><span className="bold">75%</span> people mentioned that they do not know what the plant
                                is.
                            </li>
                            <li><span className="bold">60%</span> They find it hard to find correct location and they
                                have to ask someone or use Google Map.
                            </li>
                            <li><span className="bold">70%</span> vistors are willing to try AR technology in order to
                                resolve learning and the development of more interactions. However, some people dislike
                                AR distracts attention from their children.
                            </li>
                        </ul>
                    </div>
                    <div className="content-container-right">
                        <h1>Affinity Map</h1>
                        <p>I chose three areas I want to focus on:</p>
                        <ul className="marginBottom">
                            <li>AR</li>
                            <li>Learning</li>
                            <li>Family</li>
                        </ul>
                        <img alt="" src={Infinitymap}/>
                    </div>
                </section>
                <section className="one-column-container paddingTopZero">
                    <h1>Scenario</h1>
                    <p className="marginBottom">Lily is a young adult and like to plant new plants so she usually go to
                        the Royal Botanic Gardens to learn how to plant better and participate in many workshops. She is
                        also like to see fine art in the public space. However, she is from Japan and she finds it
                        difficult to understand the description of the specific plant in the class. Lily also hopes that
                        she is able to make friends who have the similar interests by activities.</p>
                </section>
                <section className="two-column-container">
                    <div className="two-column-container-left">
                        <h1>Persona</h1>
                        <p className="marginBottom">Based on the user research, most people could accept technology to
                            help learning and spend time with their family or friends.</p>
                        <img alt="persona" className="marginBottom" src={Personapic}/>
                    </div>
                    <div className="two-column-container-right">
                        <h1>Journey Map</h1>
                        <p className="marginBottom">According to my storyboard, I made a journey map in order to see how
                            Tom feeling when he used my app. From the process, a number of points I aim to develop
                            crucial functions:</p>
                        <ul>
                            <li>How might we use AR technology to help vistors learn effectively?</li>
                            <li>How might we collect beneficial information for users to find workshops and activities
                                easily?
                            </li>
                            <li>How might we enable people to spend a lot of time doing activities with family or
                                friends in the Royal Botanic Garden?
                            </li>
                        </ul>
                        <img alt="journeyMap" src={Journeymap}/>
                    </div>
                </section>
                <div className="section-line">DESIGN EXPLORATION</div>
                <section className="content-container">
                    <div className="content-container-left">
                        <h1>Wireflow</h1>
                        <p className="marginBottom">I created a user flow diagram to make this app through a number of
                            process. <span className="bold">"As a user, I want to utilise AR technology to understand what I discovered the plant."</span>
                        </p>
                        <img alt="wireflow" src={Wireflow}/>
                    </div>
                    <div className="content-container-center">
                        <h1>UI Analysis</h1>
                        <p className="marginBottom">In order to design better App, I analyse offical app related to
                            their color and UI Style even botton.</p>
                        <p className="marginBottom">I chose their green color as my primary color and fresh experience
                            with minimal design.</p>
                        <img alt="appanalysis" src={Appanalysis}/>
                    </div>
                    <div className="content-container-right">
                        <h1>IOT Design</h1>
                        <p>From my interviews, I found that people feel bored when they are walking so I decided to add
                            a feature which is for visitors who are able to adjust colour when people want to have
                            different look on the plant in the Royal Botanic Garden. This will be interesting for them
                            to try on the events.</p>
                        <img alt="iot" className="center" style={{width: "40%", height: ""}} src={IOTDesign}/>
                    </div>
                </section>
                <section className="two-column-container">
                    <div className="two-column-container-left">
                        <h1 className="marginBottom">Design MoodBoard 1</h1>
                        <p>When I went to the Royal Botanic Garden, I feel evergreen place. People do not feel
                            tired and forests make you smooth without any obstructions. Finally, I decided to choose
                            MoodBoard-1 is because the Royal Botanic Garden has established friendly environment for
                            family and friends so
                            the place should not include luxurious color in the garden.
                        </p>
                        <img alt="moodboard1" className="marginBottom" src={Moodboard1}/>
                        <img alt="styleTitle1" src={StyleTiles1}/>
                    </div>
                    <div className="two-column-container-right">
                        <h1>Design MoodBoard 2</h1>
                        <p>Noble color which are red and purple around environment makes people live in heaven.</p>
                        <p className="marginBottom"></p>
                        <img alt="moodboard2" src={Moodboard2}/>
                        <img alt="styleTiles" src={StyleTiles2}/>
                    </div>
                </section>
                <div className="section-line">PROTOTYPE & Design</div>
                <div className="muckupSection01"></div>
                <section className="two-column-container">
                    <div className="two-column-container-left">
                        <h1 className="orange">Final Prototype</h1>
                        <p>When I designed all of design documents, I chose one design style and develop. This is to
                            show how users enter the app and interact with it in order to achieve their purposes.</p>
                        <h3>Design solutions:</h3>
                        <ul>
                            <li>Collection of the Royal Botanic Garden information facilitates registration events and
                                engagement
                            </li>
                            <li>The dictionary function enables plant lovers to learn effectively</li>
                            <li>AR technology helps people learn and identify what plants are</li>
                        </ul>
                        <ReactPlayer className="ShowvideoIntro" url='https://www.youtube.com/watch?v=HGnCpNxi7ck'
                                     config={{
                                         youtube: {
                                             playerVars: {showinfo: 1}
                                         }
                                     }}/>
                        <div className="ShowlinkIntro">
                            <a target="_blank" rel="noopener noreferrer"
                               href="https://youtu.be/HGnCpNxi7ck">
                                Link to Prototype Video
                            </a>
                        </div>
                    </div>
                    <div className="two-column-container-right">
                        <h1>User Test</h1>
                        <p>I ask a user to utilise my app. When I ask him tasks that I created, I observe his behaviour
                            and feeling so I am able to design better user process.</p>
                        <h3>Design Challenge & Feedback:</h3>
                        <ul>
                            <li>The tester dose not know how to go back the last page</li>
                            <li>AR Learning technology makes users impression</li>
                            <li>Spending too much time looking for specific page</li>
                        </ul>

                        <ReactPlayer className="ShowvideoIntro" url='https://www.youtube.com/watch?v=HsD7tQEqg5Y'
                                     config={{
                                         youtube: {
                                             playerVars: {showinfo: 1}
                                         }
                                     }}/>
                        <div className="ShowlinkIntro">
                            <a target="_blank" rel="noopener noreferrer"
                               href="https://youtu.be/HsD7tQEqg5Y">
                                Link to User Test Video
                            </a>
                        </div>
                    </div>
                </section>
                <div className="section-line">SUMMARY</div>
                <section className="content-container paddingTopZero">
                    <div className="content-container-center">
                        <h1>User Research & Test</h1>
                        <p className="marginBottom">I did not do interview effectively so I could not look for users'
                            needs.
                            In the user test video, I could not let the user speak loudly about what they feel and
                            perspectives when they spend too much time and hesitate to make decisions.
                        </p>
                        <h1>Visual Design</h1>
                        <p className="marginBottom">Most problems on my application is recognition because some people
                            are confused about icon and text description. Others spend too much time looking for their
                            purposes. I think if I change layout abd icon. This might be beneficial for users to
                            read.</p>
                        <h1>Technical Function</h1>
                        <p className="marginBottom">Moreover, AR originally is designed for detection and 3D model.
                            However, the lack of interations so I decided to add visual effects and scroll-bar to change
                            size. As a result, users are able to know this is interactive rather than touching the
                            screen many times.</p>
                        <h1>Next Plan</h1>
                        <p>I will involve Royal Botanic Gardens's events, educational tours, I believe that the app is
                            beneficial for engagements and playful experience for people to learn and relax. Moreover,
                            Using AR technology assist foreigners to understand what they can visit and introduce
                            Australia culture.</p>
                    </div>
                </section>
                <div className="muckupSection02"></div>
            </div>
        </>
    );
};

export default RoyalBotanicGarden;
