import React, { Component } from 'react'
import {uiuxworks} from './UIUXWorks';
import UiUxWorkList from '../UiUxWorkList/UiUxWorkList';
// import UIUX from '../WorkContainer/pics/UIUX.png';
import './UiUxPage.css'

export default class UiUX extends Component {
    render() {
        return (
            <>
                <div className="uiuxoverall">
                    <div className="uiuxIntro">
                        {/* <img alt={"UIUX"}
                            src={UIUX}
                            key="0"
                        /> */}
                    </div>
                    <div>
                        <UiUxWorkList uiuxworksfoo={uiuxworks}/>
                    </div>
                </div>
            </>
        )
    }
}
