import React, { Component } from 'react'
import "./Submenu.scss";
import {Link} from 'react-router-dom';

export default class Submenu extends Component {

  state = {
    shrinkMenu: false,
  }

  handleScroll = () => {
    if(window.scrollY <= 110) {
      this.setState({
        shrinkMenu:false,
      })
    } else if(window.scrollY > 110){
      this.setState({
        shrinkMenu:true,
      })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll',this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll',this.handleScroll);
  }

  render() {
    
    const fontNavclasses = ["fontNavSize"];
    if(this.state.shrinkMenu) {
      fontNavclasses.push("shrink");
    }

    return (
      <ul className="nav__submenu">
        <li className={`${fontNavclasses.join(" ")} nav__submenu-item`}>
          <Link to="/uiux" >                              
            UI / UX                       
          </Link>  
        </li>
        <li className={`${fontNavclasses.join(" ")} nav__submenu-item`}>
          <Link to="/xr">
            XR
          </Link>
        </li>
        {/* <li className={`${fontNavclasses.join(" ")} nav__submenu-item`}>
        <a target="_blank" rel="noopener noreferrer" href="https://gamemeet.cooloojayoo.vercel.app/">
            Web
        </a>
          <Link to="/xr">
            Web 
          </Link>
        </li> */}
        {/* <li className={`${fontNavclasses.join(" ")} nav__submenu-item`}>
          <Link to="/foodfoundry">
            App
          </Link>
        </li> */}
      </ul>
    )
  }
}

