import React, {Component} from 'react';
import HeroPage from '../components/HeroPage/HeroPage';
import About from '../components/About/About';
import TopWork from '../components/TopWork/TopWork';
import FeatureWorkSection from '../components/FeatureWorkSection/FeatureWorkSection';
import UIUXSection from '../components/UIUXSection/UIUXSection';
// import PlaygroundsSection from '../components/PlayGroundSection/PlayGroundSection';
import WebSection from '../components/WebSection/WebSection';
import SectionDivider from '../components/SectionDivider/SectionDivider';
import {Element} from 'react-scroll';

class App extends Component {

    //After render method
    componentDidMount() {
        // fetch('https://jsonplaceholder.typicode.com/users')
        // .then(response => {
        //    return response.json();
        // })
        // .then(works=> {
        //     this.setState({works:works})
        // })
    }

    render() {
        return (
            <div className="tc">
                <HeroPage/>
                <Element name="Web">
                    <WebSection/>
                </Element>
                <Element name="FeatureWork">
                    <FeatureWorkSection/>
                    <TopWork />
                </Element>
                <SectionDivider/>
                <Element name="UIUX">
                    <UIUXSection />
                </Element>
                {/* <Element name="Playground">
                    <PlaygroundsSection/>
                </Element> */}
                <Element name="About">
                    <About/>
                </Element>
             </div>
        )
    }
}

export default App;


