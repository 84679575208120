import React from 'react';
import TCLLogo from './Logo.png';
import {Link} from 'react-router-dom';

const Logo = (props) => {
  const classes = ["logo"];

  if(props.shrink) {
    classes.push("shrink");
  }

  const template = <div
        className={classes.join(" ")}
        style={{
          width:props.width,
          height:props.height,
          background:`url(${TCLLogo}) no-repeat`,
          backgroundSize:'contain',
        }} 
  >
  </div>

  if (props.link) {
    return (
       <Link to={props.linkTo}>
          {template}
       </Link>
    )
  } else{
    return template
  }
}

export default Logo;