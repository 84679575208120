import React from "react";

const SvgBehanceLogo = props => (
    <svg viewBox="0 0 94 94" width="1.5em" height="1.5em" {...props}>
      <g fill="#006DF0">
        <path d="M68.77 41.799c-7.32 0-8.34 7.307-8.34 7.307h15.566c.001-.001.093-7.307-7.226-7.307zM33.453 49.105H19.658V61.77h13.157c1.979-.055 5.633-.676 5.633-6.153 0-6.576-4.995-6.512-4.995-6.512zM36.804 36.154c0-4.93-3.352-4.93-3.352-4.93H19.658v10.574h12.939c2.236.001 4.207-.714 4.207-5.644z" />
        <path d="M89 0H5a5 5 0 0 0-5 5v84a5 5 0 0 0 5 5h84a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5zM58.389 25.594H77.94v5.836H58.389v-5.836zm-9.884 30.611c0 14.463-15.053 13.986-15.053 13.986H8.793V22.803h24.66c7.493 0 13.409 4.137 13.409 12.617s-7.23 9.018-7.23 9.018c9.532 0 8.873 11.767 8.873 11.767zm36.574-.775H60.521c0 8.805 8.34 8.248 8.34 8.248 7.877 0 7.6-5.098 7.6-5.098h8.34c0 13.529-16.216 12.604-16.216 12.604-19.46 0-18.21-18.117-18.21-18.117S50.359 34.86 68.584 34.86c19.182-.001 16.495 20.57 16.495 20.57z" />
      </g>
    </svg>
  );
  
  export default SvgBehanceLogo;