import React from "react";

const SvgUxuidesign = props => (
  <svg
    id="UXUIDESIGN_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 64 64"
    {...props}
    width="5em"
    height="5em"
  >
    <style>
      {
        ".UXUIDESIGN_svg__st0{fill:#f8f3f3}.UXUIDESIGN_svg__st1{fill:#63312d}.UXUIDESIGN_svg__st11{fill:#f7db9b}"
      }
    </style>
    <path
      d="M55.548 13.663h-6.466 2.687a3 3 0 0 1 3 3v25.378a3 3 0 0 1-3 3h-2.687 6.466a3.113 3.113 0 0 0 3.113-3.114V16.776a3.113 3.113 0 0 0-3.113-3.113z"
      fill="#db785a"
    />
    <path
      className="UXUIDESIGN_svg__st1"
      d="M55.548 45.541h-6.466v-1h6.466a2.616 2.616 0 0 0 2.613-2.613V16.776a2.616 2.616 0 0 0-2.613-2.613h-6.466v-1h6.466a3.617 3.617 0 0 1 3.613 3.613v25.151a3.618 3.618 0 0 1-3.613 3.614z"
    />
    <path
      id="UXUIDESIGN_svg__XMLID_220_"
      d="M51.77 45.041H11.457a3 3 0 0 1-3-3V16.663a3 3 0 0 1 3-3H51.77a3 3 0 0 1 3 3v25.378a3 3 0 0 1-3 3z"
      fill="#f9a287"
    />
    <path
      d="M10.144 43.729V17.334c0-1.095.888-1.983 1.983-1.983h41.33c.389 0 .757.08 1.098.214a2.997 2.997 0 0 0-2.786-1.902H11.457a3 3 0 0 0-3 3v25.378c0 1.268.79 2.347 1.902 2.786a2.975 2.975 0 0 1-.215-1.098z"
      fill="#ffc8bb"
    />
    <path
      className="UXUIDESIGN_svg__st1"
      d="M51.77 45.541H11.457c-1.93 0-3.5-1.57-3.5-3.5V16.663c0-1.93 1.57-3.5 3.5-3.5H51.77c1.93 0 3.5 1.57 3.5 3.5v25.378c0 1.93-1.571 3.5-3.5 3.5zM11.457 14.163a2.503 2.503 0 0 0-2.5 2.5v25.378c0 1.378 1.121 2.5 2.5 2.5H51.77c1.379 0 2.5-1.122 2.5-2.5V16.663c0-1.378-1.121-2.5-2.5-2.5H11.457z"
    />
    <path
      id="UXUIDESIGN_svg__XMLID_217_"
      className="UXUIDESIGN_svg__st0"
      d="M12.284 17.425h38.918v24.068H12.284z"
    />
    <path
      fill="#e1dddd"
      d="M13.938 19.099h37.264v-1.674H12.284v24.068h1.654z"
    />
    <path
      className="UXUIDESIGN_svg__st1"
      d="M51.202 41.993H12.284a.5.5 0 0 1-.5-.5V17.425a.5.5 0 0 1 .5-.5h38.918a.5.5 0 0 1 .5.5v24.068c0 .277-.223.5-.5.5zm-38.418-1h37.918V17.925H12.784v23.068z"
    />
    <path
      d="M49.086 45.044v1.7c0 1.98-1.61 3.59-3.6 3.59H6.676c-.74 0-1.43-.22-2-.61h-.01a3.607 3.607 0 0 1-1.58-2.98v-1.7h46z"
      fill="#f9dd9d"
    />
    <path
      d="M4.506 46.714v1.92c0 .38.06.75.16 1.09a3.607 3.607 0 0 1-1.58-2.98v-1.7h46v1.67H4.506z"
      fill="#ffeecf"
    />
    <path
      className="UXUIDESIGN_svg__st1"
      d="M45.488 50.837H6.679a4.099 4.099 0 0 1-4.094-4.094V45.04a.5.5 0 0 1 .5-.5h45.997a.5.5 0 0 1 .5.5v1.703a4.099 4.099 0 0 1-4.094 4.094zM3.585 45.541v1.203a3.098 3.098 0 0 0 3.094 3.094h38.81a3.098 3.098 0 0 0 3.094-3.094v-1.203H3.585z"
    />
    <path
      d="M49.082 45.041v1.702a3.594 3.594 0 0 1-3.594 3.594h11.833a3.594 3.594 0 0 0 3.594-3.594v-1.702H49.082z"
      fill="#efc36a"
    />
    <path
      className="UXUIDESIGN_svg__st1"
      d="M57.321 50.837H45.488a.5.5 0 0 1 0-1 3.098 3.098 0 0 0 3.094-3.094V45.04a.5.5 0 0 1 .5-.5h11.833a.5.5 0 0 1 .5.5v1.703a4.099 4.099 0 0 1-4.094 4.094zm-9.155-1h9.155a3.098 3.098 0 0 0 3.094-3.094V45.54H49.582v1.203a4.084 4.084 0 0 1-1.416 3.094z"
    />
    <path
      className="UXUIDESIGN_svg__st11"
      d="M24.9 38.566l-1.165-1.164-1.164-1.165L38.67 20.138l2.329 2.329z"
    />
    <path
      className="UXUIDESIGN_svg__st1"
      d="M24.899 39.066a.502.502 0 0 1-.354-.146l-2.328-2.329a.5.5 0 0 1 0-.707l16.099-16.099a.5.5 0 0 1 .707 0l2.329 2.329a.5.5 0 0 1 0 .708l-16.1 16.099a.501.501 0 0 1-.353.145zm-1.621-2.829l1.621 1.622 15.393-15.392-1.622-1.622-15.392 15.392z"
    />
    <path
      transform="rotate(-45.017 31.668 28.133)"
      className="UXUIDESIGN_svg__st1"
      d="M31.17 27.43h1v1.407h-1z"
    />
    <path
      transform="rotate(-45.017 30.437 29.364)"
      className="UXUIDESIGN_svg__st1"
      d="M29.939 28.661h1v1.407h-1z"
    />
    <path
      transform="rotate(-45.017 28.385 31.664)"
      className="UXUIDESIGN_svg__st1"
      d="M27.887 30.961h1v1.407h-1z"
    />
    <path
      transform="rotate(-45.001 26.644 33.352)"
      className="UXUIDESIGN_svg__st1"
      d="M26.144 32.648h1v1.407h-1z"
    />
    <path
      transform="rotate(-45.017 24.822 35.131)"
      className="UXUIDESIGN_svg__st1"
      d="M24.324 34.429h1v1.407h-1z"
    />
    <path
      transform="rotate(-44.972 37.401 22.53)"
      className="UXUIDESIGN_svg__st1"
      d="M36.903 21.829h1v1.407h-1z"
    />
    <path
      transform="rotate(-44.972 35.658 24.218)"
      className="UXUIDESIGN_svg__st1"
      d="M35.16 23.516h1v1.407h-1z"
    />
    <path
      transform="rotate(-44.972 33.838 25.998)"
      className="UXUIDESIGN_svg__st1"
      d="M33.34 25.297h1v1.407h-1z"
    />
    <path
      fill="#82d9ff"
      d="M39.072 34.713l1.292 3.408-3.408-1.292-14.469-14.468 2.117-2.117z"
    />
    <path
      className="UXUIDESIGN_svg__st0"
      d="M36.956 36.829l3.408 1.292-1.292-3.408z"
    />
    <path
      transform="rotate(-45.001 24.921 22.679)"
      className="UXUIDESIGN_svg__st11"
      d="M23.424 20.732h2.994v3.892h-2.994z"
    />
    <path
      className="UXUIDESIGN_svg__st1"
      d="M40.364 38.621a.517.517 0 0 1-.177-.032l-3.409-1.292a.503.503 0 0 1-.177-.114L22.134 22.714a.5.5 0 0 1 0-.707l2.116-2.117a.514.514 0 0 1 .707 0l14.469 14.468a.5.5 0 0 1 .114.176l1.292 3.409a.504.504 0 0 1-.468.678zM37.232 36.4l2.271.86-.86-2.27-14.039-14.039-1.409 1.41L37.232 36.4z"
    />
    <path
      transform="rotate(-45.001 38.013 35.772)"
      className="UXUIDESIGN_svg__st1"
      d="M36.517 35.271h2.994v1h-2.994z"
    />
    <path
      transform="rotate(-45.001 26.297 24.055)"
      className="UXUIDESIGN_svg__st1"
      d="M24.801 23.554h2.993v1h-2.993z"
    />
    <path
      className="UXUIDESIGN_svg__st1"
      d="M38.862 28.745h5.758v1h-5.758zM45.41 28.745h1.129v1H45.41zM18.866 28.745h5.757v1h-5.757zM16.947 28.745h1.129v1h-1.129z"
    />
  </svg>
);

export default SvgUxuidesign;